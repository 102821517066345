import type { EnvVariableName } from './types'

const ENV_VARS: Record<EnvVariableName, string | undefined> = {
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  AMPLITUDE_EXPERIMENT_DEPLOYMENT_ID: process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_DEPLOYMENT_ID,
  BRAND_NAME: process.env.NEXT_PUBLIC_BRAND_NAME,
  CHAT_LIST_POLLING_INTERVAL: process.env.NEXT_PUBLIC_CHAT_LIST_POLLING_INTERVAL,
  CONTENTFUL_KEY: process.env.NEXT_PUBLIC_CONTENTFUL_KEY,
  CONTENTFUL_SERVER_URL: process.env.NEXT_PUBLIC_CONTENTFUL_SERVER_URL,
  GOOGLE_ANALYTICS_TRACKING_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID,
  GOOGLE_MAPS_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
  GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  HOTJAR_SITE_ID: process.env.NEXT_PUBLIC_HOTJAR_SITE_ID,
  IMAGES_CDN_URL: process.env.NEXT_PUBLIC_IMAGES_CDN_URL,
  INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  LOCATIONIQ_KEY: process.env.NEXT_PUBLIC_LOCATIONIQ_KEY,
  PLATFORM: process.env.NEXT_PUBLIC_PLATFORM,
  SCHIBSTED_CLIENT_ENV: process.env.NEXT_PUBLIC_SCHIBSTED_CLIENT_ENV,
  SCHIBSTED_SE_CLIENT_ID: process.env.NEXT_PUBLIC_SCHIBSTED_SE_CLIENT_ID,
  SCHIBSTED_FI_CLIENT_ID: process.env.NEXT_PUBLIC_SCHIBSTED_FI_CLIENT_ID,
  SCHIBSTED_COOKIE_CONSENT_BASE_ENDPOINT: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_BASE_ENDPOINT,
  SCHIBSTED_COOKIE_CONSENT_DISABLED: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_DISABLED,
  SCHIBSTED_COOKIE_CONSENT_GROUP_PM_ID: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_GROUP_PM_ID,
  SCHIBSTED_COOKIE_CONSENT_PROPERTY_HREF: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_PROPERTY_HREF,
  SCHIBSTED_COOKIE_CONSENT_PROPERTY_ID: process.env.NEXT_PUBLIC_SCHIBSTED_COOKIE_CONSENT_PROPERTY_ID,
  SCHIBSTED_SE_SESSION_DOMAIN: process.env.NEXT_PUBLIC_SCHIBSTED_SE_SESSION_DOMAIN,
  SCHIBSTED_FI_SESSION_DOMAIN: process.env.NEXT_PUBLIC_SCHIBSTED_FI_SESSION_DOMAIN,
  SENTRY_DISABLED: process.env.NEXT_PUBLIC_SENTRY_DISABLED,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SERVER_URL: process.env.NEXT_PUBLIC_SERVER_URL,
  STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY,
  SUNSHINE_CONV_APP_ID: process.env.NEXT_PUBLIC_SUNSHINE_CONV_APP_ID,
  SUNSHINE_CONV_WHATSAPP_ID: process.env.NEXT_PUBLIC_SUNSHINE_CONV_WHATSAPP_ID,
  TINK_ID: process.env.NEXT_PUBLIC_TINK_ID,
  TINK_TEST_ENV: process.env.NEXT_PUBLIC_TINK_TEST_ENV,
  WSS_SERVER_URL: process.env.NEXT_PUBLIC_WSS_SERVER_URL,
}

export function getEnv(envVariableName: EnvVariableName) {
  return ENV_VARS[envVariableName]
}

export function isProductionBuild() {
  return process.env.NODE_ENV === 'production'
}
export function isServerStaging() {
  return getEnv('SERVER_URL')?.includes('staging-api.qasa')
}
