import * as Sentry from '@sentry/nextjs'

export function identifyUser({ email, id }: { email: string; id: string }) {
  Sentry.setUser({
    id,
    email,
    ip_address: '{{auto}}',
  })
}

export function reportError(message: string, errors: Record<string, unknown>) {
  Sentry.captureException(new Error(message), {
    contexts: {
      errors,
    },
  })
}
export function reportWarning(message: string, errors: Record<string, unknown>) {
  Sentry.captureException(new Error(message), {
    level: 'warning',
    contexts: {
      errors,
    },
  })
}

export { Sentry }
