import { getEnv, isProductionBuild } from '../../env'
import { getCurrentLanguage } from '../../utils/i18n'
import { reportError } from '../sentry'

import { ConsentLanguages } from './schibsted-sourcepoint.types'

export enum Categories {
  advertising = 'CMP:advertising',
  analytics = 'CMP:analytics',
  marketing = 'CMP:marketing',
}
export type UnsubscribeFunction = () => void
type ConsentValue = '1' | '0'

declare const window: {
  _scc_: {
    getPermission: (category: Categories, callback: (value: ConsentValue) => void) => void
    getPermissionSync: (category: Categories) => ConsentValue
    subscribe: (category: Categories, callback: (value: ConsentValue) => void) => UnsubscribeFunction
    showPrivacyManager: () => void
  }
} & Window

// TODO: fix language code type
type SiteLanguage = 'en' | 'fr' | 'sv' | 'fi'
/* Doing this mapping since some of our default languages have different codes or are not supported */
const consentLanguageMap: Record<SiteLanguage, ConsentLanguages> = {
  sv: ConsentLanguages.se,
  en: ConsentLanguages.en,
  fi: ConsentLanguages.fi,
  fr: ConsentLanguages.en,
}

export function getIsSourcepointClientActive() {
  return typeof window !== 'undefined' && Boolean(window._scc_)
}
export function showSchibstedCookieConsentDialog() {
  if (!getIsSourcepointClientActive()) {
    return
  }
  window._scc_.showPrivacyManager()
}
export const getSchibstedCookieConsentValue = (category: Categories) => {
  if (!getIsSourcepointClientActive()) {
    return false
  }
  const value = window._scc_.getPermissionSync(category)
  return convertSourcepointValueToBoolean({ value })
}

type SubscribeToSourcepointCategoryConsentProps = {
  callbackFunction: (value: ConsentValue) => void
  category: Categories
}
export function subscribeToSourcepointCategoryConsent({
  callbackFunction,
  category,
}: SubscribeToSourcepointCategoryConsentProps) {
  return window._scc_.subscribe(category, callbackFunction)
}
export function convertSourcepointValueToBoolean({ value }: { value: ConsentValue }) {
  return value === '1' ? true : false
}

export async function initSchibstedCookieConsent() {
  if (getIsSourcepointClientActive() || getEnv('SCHIBSTED_COOKIE_CONSENT_DISABLED') === 'true') {
    return
  }
  const propertyHref = getEnv('SCHIBSTED_COOKIE_CONSENT_PROPERTY_HREF')
  const currentLanguage = getCurrentLanguage()
  const config = {
    baseEndpoint: getEnv('SCHIBSTED_COOKIE_CONSENT_BASE_ENDPOINT'),
    propertyId: parseInt(getEnv('SCHIBSTED_COOKIE_CONSENT_PROPERTY_ID') || '', 10),
    consentLanguage: consentLanguageMap[currentLanguage as SiteLanguage],
    groupPmId: parseInt(getEnv('SCHIBSTED_COOKIE_CONSENT_GROUP_PM_ID') || '', 10),
    ...(propertyHref && { propertyHref }),
  }
  try {
    // We need to use dynamic import here to avoid loading the sourcepoint client in the server
    const { scc } = await import('@schibsted/sourcepoint')
    scc(window, document, navigator, config)
  } catch (error) {
    if (isProductionBuild()) {
      reportError('Could not initialize @schibsted/sourcepoint cookie consent manager', { error })
    }
  }
}
